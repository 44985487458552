<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Hintergrundwissen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-card-text>
        <p>
          Die Aufgaben des kleinen Einspluseins automatisiert wiedergeben zu
          können, ebenso wie das entdecken und nutzen von Aufgabenbeziehungen,
          sind ein zentrales Lernziel der Schuleingangsphase. Das Automatisieren
          sollte nicht durch das isolierte Auswendiglernen der einzelnen
          Aufgaben geschehen, sondern durch Ableitungsstrategien, ausgehend von
          Kernaufgaben. Um im Unterricht die unterschiedlichen Rechenwege der
          Addition und Subtraktion zu thematisieren, müssen zuvor tragfähige
          Zahl- und Operationsvorstellungen (siehe auch
          <a href="/operationsverstaendnis">Operationsverständnis</a> und
          <a href="/zahlverstaendnis">Zahlverständnis</a>) aufgebaut worden
          sein.
        </p>
        <p>
          Ein sicheres Beherrschen des Einspluseins, wozu neben dem
          automatisierten Ermitteln von Lösungen auch der flexible Umgang mit
          Ableitungsstrategien gehört, bildet die Grundlage für das Kopfrechnen,
          aber auch das halbschriftliche und schriftliche Rechnen. Gesicherte
          Fähigkeiten im Umgang mit den Aufgaben des kleinen 1+1 ermöglichen den
          Lernenden, sich sicher in größeren Zahlenräumen zu bewegen und
          flexibel rechnen zu können.
        </p>
        <br />
        <AppLiteraturEPE />
      </v-card-text>
    </v-card>
    <AppBottomNavEPE
      next="/einspluseins/hintergrundwissen/grundvorstellungen"
      back="/einspluseins"
    />
  </div>
</template>

<script>
import AppBottomNavEPE from "@/common/AppBottomNavEPE";
import AppLiteraturEPE from "@/common/AppLiteraturEPE";
export default {
  components: {
    AppBottomNavEPE,
    AppLiteraturEPE,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
